<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 商品列表 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-button type="primary" class="btn" @click="search()" v-hasPermi="['commodity:list:query']">搜索</a-button>
        <a-button class="all_boder_btn" @click="onAddCommodity"
          >新建商品</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane :key="0" tab="全部商品"></a-tab-pane>
        <a-tab-pane
          :key="item.code"
          v-for="item in classData"
          :tab="item.name"
        ></a-tab-pane>
      </a-tabs>

      <a-table
        class="table-template"
        :rowKey="(item) => item.productId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * 10 + i + 1 }}
          </div>
        </template>

        <template slot="sort" slot-scope="item,row">
          <a-input-number id="inputNumber" v-model="item" :min="1" @change="onSortChange(item,row)" />
        </template>

        <template slot="operation" slot-scope="text, row">
          <div class="btn_router_link" v-if="row.type == 3 || row.type == 2">
            <router-link
              v-hasPermi="['commodity:publish:updatestatus']"
              v-if="text.noGround != '已上架'"
              to
              @click.native="changeState(text, 1)"
              >上架</router-link
            >
            <router-link v-else to v-hasPermi="['commodity:publish:updatestatus']"  @click.native="changeState(text, 0)"
              >下架</router-link
            >
            <span>|</span>
            <router-link to @click.native="selEdit(text)" v-hasPermi="['commodity:info:edit']">编辑</router-link>
            <span v-if="text.noGround != '已上架'">|</span>
            <!-- <a-popconfirm
              title="确认删除？"
              ok-text="是"
              cancel-text="否"
              @confirm="deleteitem(text)"
            > -->
              <router-link v-if="text.noGround != '已上架'" @click.native="deleteitem(text)" v-hasPermi="['commodity:info:delete']" to>删除</router-link>
            <!-- </a-popconfirm> -->
          </div>
          <div v-else>-</div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "商品类别",
    align: "center",
    dataIndex: "categoryName",
  },
  {
    title: "排序",
    align: "center",
    dataIndex: "sort",
    scopedSlots: {
      customRender: "sort",
    },
  },
  {
    title: "商品编码",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "优惠价",
    align: "center",
    dataIndex: "couponPrice",
  },
  {
    title: "销量",
    align: "center",
    dataIndex: "salesVolume",
  },
  // {
  //   title: "库存",
  //   align: "center",
  //   dataIndex: "inventory",
  // },
  {
    title: "状态",
    align: "center",
    dataIndex: "noGround",
  },

  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
// import { sort } from "core-js/core/array";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNum: 1, //页码
      pageSize: 10, // 页数
      // 关键字
      name: "",
      // 全部分类
      classData: [],
      // 分类id
      code: "",
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // tab切换
    callback(e) {
      this.code = e;
      this.pageNum = 1;
      this.pageSize = 10;
      this.getdataList();
    },
    // 换页
    selectpage(e) {
    },
    // 编辑
    selEdit(e) {
      this.$router.push({
        path: "/admin/Commodity/NewProduct",
        query: { id: e.productId, operation: 2 },
      });
    },
    // 新建商品
    onAddCommodity() {
      this.$router.push({
        path: "/admin/Commodity/NewProduct",
        query: { id: 1, operation: 1 },
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/list",
        method: "get",
        params: {
          code: this.code ? this.code : '',
          page: this.pageNum,
          size: this.pageSize,
          name: this.name,
        },
      }).then((res) => {
        this.loading = false
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 获取分类数据
    getclassData() {
      this.$ajax({
        url: "/hxclass-management/product-category/manage/select-list",
        method: "get",
        params: {
          pid: 0,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classData = res.data;
        } else {
        }
      });
    },

    // 改变商品状态
    changeState(e, r) {
      this.$ajax({
        url:
          "/hxclass-management/product-archives/manage/ground?productId=" +
          e.productId +
          "&ground=" +
          r,
        method: "put",
        params: {
          // productId: e.productId,
          // ground: r,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getdataList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除商品
    deleteitem(e) {
      this.$confirm({
        title: '确定删除该商品吗?',
        centered: true,
        okText: '确认',
        onOk:()=> {
          this.$ajax({
            url:"/hxclass-management/product-archives/manage/delete/" + e.productId,
            method: "delete",
            params: {
              // productId: e.productId,
              // ground: r,
            },
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.$message.success("操作成功");
              this.getdataList();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
      
    },
    // 修改位置
    onSortChange(e,item) {
      console.log(e)
      item.sort = e;
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/product-archives/manage/updateProduct",
            method: "put",
            params: {
              productId: item.productId,
              sort: e
            }
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success("操作成功");
              _this.getdataList();
            }
          });
        // console.log('zoule')
      },600)
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
    this.getclassData();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  /deep/ .ant-tabs-bar {
    margin: 0;
  }
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .right {
    flex: 1;
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
</style>
